import * as React from 'react'
import { graphql } from 'gatsby'
import { useRef } from 'react'
import PageMainSection from '../components/reusable/PageMainSection/PageMainSection'
import MainLayout from '../layouts/MainLayout'
import PostContent from '../components/Pages/OnePost/PostContent/PostContent'
import PostContextProvider from '../utils/PostContextProvider'

const CookiePolicy = ({
  data: {
    wpPage: {
      title,
      metaTags,
      page_policy: { body },
    },
  },
  location,
}) => {
  const content = useRef()
  return (
    <PostContextProvider>
      <MainLayout seoData={metaTags} location={location}>
        <PageMainSection title={title} />
        <div ref={content}>
          <PostContent data={body} url={location.href} />
        </div>
      </MainLayout>
    </PostContextProvider>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 69 }) {
      title
      metaTags {
        seoTitle
        seoDescription
        seoKeywords
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }
      page_policy {
        body
      }
    }
  }
`

export default CookiePolicy
